define('ember-cp-validations/validators/exclusion', ['exports', 'ember-cp-validations/-private/ember-validator'], function (exports, _emberCpValidationsPrivateEmberValidator) {
  /**
   * Copyright 2016, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  'use strict';

  /**
   *  <i class="fa fa-hand-o-right" aria-hidden="true"></i> [See All Options](#method_validate)
   *
   *  Validates that the attributes’ values are not included in a given list. All comparisons are done using strict equality so type matters! For range, the value type is checked against both lower and upper bounds for type equality.
   *
   *  ## Examples:
   *
   *  ```javascript
   *  validator('exclusion', {
   *    in: ['Admin', 'Super Admin']
   *  })
   *  validator('exclusion', {
   *    range: [0, 5] // Cannot be between 0 (inclusive) to 5 (inclusive)
   *  })
   *  ```
   *
   *  @class Exclusion
   *  @module Validators
   *  @extends Base
   */
  exports['default'] = _emberCpValidationsPrivateEmberValidator['default'].extend({
    _evType: 'exclusion'
  });
});