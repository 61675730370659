define('dc-tabs/components/dc-tabs', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var A = _ember['default'].A;
  var ArrayProxy = _ember['default'].ArrayProxy;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;

  exports['default'] = Component.extend({
    tagName: 'dc-tabs',
    activeTab: null,
    'selected-index': 0,

    tabPanels: computed(function () {
      return ArrayProxy.create({
        content: new A()
      });
    }),

    select: function select(tab) {
      var tabIndex = tab.get('custom-index') || tab.get('index');
      this.set('activeTab', tab);
      this.set('selected-index', tabIndex);
    },

    actions: {
      registerTabList: function registerTabList(tabList) {
        this.set('tabList', tabList);
      },

      registerTabPanel: function registerTabPanel(tabPanel) {
        this.get('tabPanels').addObject(tabPanel);
      },

      unregisterTabPanel: function unregisterTabPanel(tabPanel) {
        this.get('tabPanels').removeObject(tabPanel);
      }
    }
  });
});