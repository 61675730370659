define('ember-elsewhere/components/from-elsewhere', ['exports', 'ember-elsewhere/templates/components/from-elsewhere', 'ember'], function (exports, _emberElsewhereTemplatesComponentsFromElsewhere, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberElsewhereTemplatesComponentsFromElsewhere['default'],
    service: _ember['default'].inject.service('ember-elsewhere'),
    tagName: '',

    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('name')) {
        this.set('name', 'default');
      }
    },

    // We don't yield any content on the very first render pass, because
    // we want to give any concurrent {{to-elsewhere}} components a chance
    // to declare their intentions first. This allows the components
    // inside us to see a meaningful initial value on their initial
    // render.
    initialized: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super();
      _ember['default'].run.schedule('afterRender', function () {
        return _this.set('initialized', true);
      });
    }
  });
});