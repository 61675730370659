define('ember-cli-showdown/components/markdown-to-html', ['exports', 'ember-cli-showdown/templates/components/markdown-to-html'], function (exports, _emberCliShowdownTemplatesComponentsMarkdownToHtml) {
  'use strict';

  var computed = Ember.computed,
      get = Ember.get,
      merge = Ember.merge,
      getOwner = Ember.getOwner;

  var CONFIG_LOOKUP = 'config:environment';

  var assign = Ember.assign;

  if (!assign) {
    assign = function assignPolyfill() {
      for (var _len = arguments.length, objects = Array(_len), _key = 0; _key < _len; _key++) {
        objects[_key] = arguments[_key];
      }

      return objects.reduce(merge);
    };
  }

  var ShowdownComponent = Ember.Component.extend({
    layout: _emberCliShowdownTemplatesComponentsMarkdownToHtml['default'],
    markdown: '',
    _globalOptions: null,

    extensions: computed(function () {
      return [];
    }),

    defaultOptionKeys: computed(function () {
      return Object.keys(showdown.getDefaultOptions());
    }).readOnly(),

    init: function init() {
      this._super.apply(this, arguments);
      var owner = getOwner(this);

      if (owner && owner.hasRegistration(CONFIG_LOOKUP)) {
        this._globalOptions = (owner.resolveRegistration(CONFIG_LOOKUP) || {}).showdown;
      }
    },

    html: computed('markdown', 'converter', function () {
      var showdownOptions = this.getShowdownProperties(get(this, 'defaultOptionKeys'));
      var converter = get(this, 'converter');

      for (var option in showdownOptions) {
        if (showdownOptions.hasOwnProperty(option) && typeof showdownOptions[option] !== 'undefined') {
          converter.setOption(option, showdownOptions[option]);
        }
      }

      return Ember.String.htmlSafe(converter.makeHtml(get(this, 'markdown')));
    }).readOnly(),

    converter: computed('extensions', function () {
      var extensions = get(this, 'extensions');

      if (typeof extensions === 'string') {
        extensions = extensions.split(' ');
      }

      return new showdown.Converter({ extensions: extensions });
    }),

    getShowdownProperties: function getShowdownProperties(keyNames) {
      var _this = this;

      return keyNames.reduce(function (accumulator, keyName) {
        var value = get(_this, keyName);

        if (value === undefined && _this._globalOptions) {
          value = get(_this._globalOptions, keyName);
        }

        accumulator[keyName] = value;

        return accumulator;
      }, {});
    }
  });

  ShowdownComponent.reopenClass({
    positionalParams: ['markdown']
  });

  exports['default'] = ShowdownComponent;
});