define('ember-concurrency/helpers/perform', ['exports', 'ember-concurrency/-helpers'], function (exports, _emberConcurrencyHelpers) {
  'use strict';

  exports.performHelper = performHelper;

  function performHelper(args, hash) {
    return (0, _emberConcurrencyHelpers.taskHelperClosure)('perform', 'perform', args, hash);
  }

  exports['default'] = Ember.Helper.helper(performHelper);
});