define('ember-composable-helpers/utils/is-equal', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = isEqual;

  var emberIsEqual = _ember['default'].isEqual;

  function isEqual(firstValue, secondValue) {
    var useDeepEqual = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

    if (useDeepEqual) {
      return JSON.stringify(firstValue) === JSON.stringify(secondValue);
    } else {
      return emberIsEqual(firstValue, secondValue) || emberIsEqual(secondValue, firstValue);
    }
  }
});