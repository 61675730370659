define('ember-powerbi/components/powerbi-report', ['exports', 'ember', 'ember-powerbi/templates/components/powerbi-report'], function (exports, _ember, _emberPowerbiTemplatesComponentsPowerbiReport) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['powerbi-frame'],
    layout: _emberPowerbiTemplatesComponentsPowerbiReport['default'],
    powerbi: _ember['default'].inject.service('powerbi'),

    accessToken: '',
    component: null,
    embedUrl: null,
    name: null,
    reportId: null,
    options: null,

    didRender: function didRender() {
      this._super.apply(this, arguments);

      if (this.validateAttributes()) {
        this.embed(this.$());
      } else if (this.component) {
        this.reset(this.$());
      }
    },

    embed: function embed(element) {
      var config = {
        type: 'report',
        embedUrl: this.embedUrl,
        accessToken: this.accessToken,
        id: this.reportId,
        uniqueId: this.name
      };

      _ember['default'].$.extend(config, this.options);

      this.component = this.get('powerbi').embed(element, config);
      var action = this.get('onEmbedded');

      if (action) {
        action(this.component);
      }
    },

    reset: function reset(element) {
      this.get('powerbi').reset(element);
      this.component = null;
    },

    validateAttributes: function validateAttributes() {
      return !_ember['default'].isEmpty(this.get('embedUrl')) && !_ember['default'].isEmpty(this.get('accessToken'));
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('powerbi').reset(this.$());
    }
  });
});