define('ember-elsewhere/services/ember-elsewhere', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var Service = _ember['default'].Service;
  var run = _ember['default'].run;
  var EmObject = _ember['default'].Object;

  exports['default'] = Service.extend({
    init: function init() {
      this._super();
      this.set('actives', EmObject.create());
      this._alive = {};
      this._counter = 1;
    },
    show: function show(sourceId, name, component) {
      this._alive[sourceId] = {
        target: name || 'default',
        component: component,
        order: this._counter++
      };
      this._schedule();
    },
    clear: function clear(sourceId) {
      delete this._alive[sourceId];
      this._schedule();
    },
    _schedule: function _schedule() {
      run.scheduleOnce('afterRender', this, this._process);
    },
    _process: function _process() {
      var newActives = {};
      var alive = this._alive;

      Object.keys(alive).forEach(function (sourceId) {
        var _alive$sourceId = alive[sourceId];
        var target = _alive$sourceId.target;
        var component = _alive$sourceId.component;
        var order = _alive$sourceId.order;

        var existing = newActives[target];
        if (!existing || existing.order < order) {
          newActives[target] = component ? { component: component, order: order } : null;
        }
      });
      this.set('actives', EmObject.create(newActives));
    }
  });
});