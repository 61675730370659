define('dc-tabs/components/dc-tab-panel', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var alias = _ember['default'].computed.alias;

  exports['default'] = Component.extend({
    tagName: 'dc-tab-panel',

    attributeBindings: ['role', 'aria-labeledby'],

    // TODO: remove this, toggleVisibility won't fire w/o it though (?)
    classNameBindings: ['active'],

    tabsComponent: alias('parentView'),
    target: alias('tabsComponent'),

    role: 'tabpanel',

    tabList: alias('tabsComponent.tabList'),

    tabPanels: alias('tabsComponent.tabPanels'),

    'aria-labeledby': alias('tab.elementId'),

    registerTabPanelOnInit: (function () {
      _ember['default'].run.schedule('afterRender', this, function () {
        this.send('registerTabPanel', this);
      });
    }).on('init'),

    willDestroyElement: function willDestroyElement() {
      this.send('unregisterTabPanel', this);
    },

    tab: computed('tabList.tabs.[]', 'tabPanels.[]', function () {
      var index = this.get('tabPanels').indexOf(this);
      var tabs = this.get('tabList.tabs');
      return tabs && tabs.objectAt(index);
    }),

    active: computed('tab.active', function () {
      return this.get('tab.active');
    })

  });
});