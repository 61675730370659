define('ember-powerbi/components/powerbi-component', ['exports', 'ember', 'ember-powerbi/templates/components/powerbi-component'], function (exports, _ember, _emberPowerbiTemplatesComponentsPowerbiComponent) {
  'use strict';

  var PowerBiComponent = _ember['default'].Component.extend({
    classNames: ['powerbi-frame'],
    layout: _emberPowerbiTemplatesComponentsPowerbiComponent['default'],
    powerbi: _ember['default'].inject.service('powerbi'),

    component: null,
    options: null,
    validationMap: null,

    init: function init() {
      this._super.apply(this, arguments);

      this.set('validationMap', {
        'report': this.validateReportOptions
      });
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var options = this.get('options');
      if (this.validateOptions(options)) {
        this.embed(this.$(), options);
      }
    },

    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var options = this.get('options');
      if (this.validateOptions(options)) {
        this.embed(this.$(), options);
      } else if (this.component) {
        this.reset(this.$());
      }
    },

    embed: function embed(element, options) {
      this.component = this.get('powerbi').embed(element, options);
      var action = this.get('onEmbedded');

      if (action) {
        action(this.component);
      }
    },

    reset: function reset(element) {
      this.get('powerbi').reset(element);
      this.component = null;
    },

    validateOptions: function validateOptions(options) {
      if (!options || !(typeof options.embedUrl === 'string' && options.embedUrl.length > 0) || !(typeof options.accessToken === 'string' && options.accessToken.length > 0)) {
        return false;
      }

      if (this.validationMap.hasOwnProperty(options.type) && typeof this.validationMap[options.type] === 'function') {
        return this.validationMap[options.type](options);
      } else {
        return false;
      }
    },

    validateReportOptions: function validateReportOptions() /* options */{
      return true;
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('powerbi').reset(this.$());
    }
  });

  PowerBiComponent.reopenClass({
    positionalParams: ['options']
  });

  exports['default'] = PowerBiComponent;
});