define('ember-powerbi/services/powerbi', ['exports', 'ember', 'powerbi'], function (exports, _ember, _powerbi) {
  'use strict';

  exports['default'] = _ember['default'].Service.extend({
    powerbi: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('powerbi', _powerbi['default']);
    },

    embed: function embed(jqueryElement, config) {
      return this.get('powerbi').embed(jqueryElement.get(0), config);
    },

    getx: function getx(jqueryElement) {
      return this.get('powerbi').get(jqueryElement.get(0));
    },

    find: function find(uniqueId) {
      return this.get('powerbi').find(uniqueId);
    },

    reset: function reset(jqueryElement) {
      this.get('powerbi').reset(jqueryElement.get(0));
    }
  });
});