define('ember-cp-validations/validators/number', ['exports', 'ember-cp-validations/-private/ember-validator'], function (exports, _emberCpValidationsPrivateEmberValidator) {
  /**
   * Copyright 2016, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  'use strict';

  /**
   *  <i class="fa fa-hand-o-right" aria-hidden="true"></i> [See All Options](#method_validate)
   *
   *  Validates that your attributes have only numeric values.
   *
   *  ## Examples
   *
   *  ```javascript
   *  validator('number') // Simple check if the value is a number
   *  validator('number', {
   *    allowString: true,
   *    integer: true,
   *    gt: 5,
   *    lte: 100
   *  })
   *  ```
   *
   *  @class Number
   *  @module Validators
   *  @extends Base
   */
  exports['default'] = _emberCpValidationsPrivateEmberValidator['default'].extend({
    _evType: 'number'
  });
});