define("ember-tooltips/templates/components/lazy-render-wrapper", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 13,
                  "column": 6
                },
                "end": {
                  "line": 17,
                  "column": 6
                }
              },
              "moduleName": "modules/ember-tooltips/templates/components/lazy-render-wrapper.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "yield", [["subexpr", "hash", [], ["hide", ["subexpr", "action", ["hide"], [], ["loc", [null, [15, 15], [15, 30]]], 0, 0]], ["loc", [null, [14, 16], [16, 9]]], 0, 0]], [], ["loc", [null, [14, 8], [16, 11]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 17,
                  "column": 6
                },
                "end": {
                  "line": 19,
                  "column": 6
                }
              },
              "moduleName": "modules/ember-tooltips/templates/components/lazy-render-wrapper.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["content", "text", ["loc", [null, [18, 8], [18, 16]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 3
              },
              "end": {
                "line": 20,
                "column": 3
              }
            },
            "moduleName": "modules/ember-tooltips/templates/components/lazy-render-wrapper.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "hasBlock", ["loc", [null, [13, 12], [13, 20]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [13, 6], [19, 13]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 22,
              "column": 0
            }
          },
          "moduleName": "modules/ember-tooltips/templates/components/lazy-render-wrapper.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "component", [["get", "tetherComponentName", ["loc", [null, [3, 16], [3, 35]]], 0, 0, 0, 0]], ["passedPropertiesObject", ["subexpr", "@mut", [["get", "passedPropertiesObject", ["loc", [null, [4, 27], [4, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "class", ["subexpr", "@mut", [["get", "class", ["loc", [null, [5, 10], [5, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "id", ["subexpr", "@mut", [["get", "id", ["loc", [null, [6, 7], [6, 9]]], 0, 0, 0, 0]], [], [], 0, 0], "classNames", ["subexpr", "@mut", [["get", "classNames", ["loc", [null, [7, 15], [7, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "_shouldShowOnRender", ["subexpr", "@mut", [["get", "_shouldShowOnRender", ["loc", [null, [9, 24], [9, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "_isInProcessOfShowing", ["subexpr", "@mut", [["get", "_isInProcessOfShowing", ["loc", [null, [10, 26], [10, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "_shouldTargetGrandparentElement", ["subexpr", "@mut", [["get", "_shouldTargetGrandparentElement", ["loc", [null, [11, 38], [11, 69]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [3, 3], [20, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 24,
            "column": 0
          }
        },
        "moduleName": "modules/ember-tooltips/templates/components/lazy-render-wrapper.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "_shouldRender", ["loc", [null, [1, 6], [1, 19]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [22, 7]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});