define("ember-can/can-mixin", ["exports", "ember"], function (exports, _ember) {
  "use strict";

  exports["default"] = _ember["default"].Mixin.create({
    canService: _ember["default"].inject.service("can"),

    can: function can(abilityName, resource, aditionalProperties) {
      return this.get("canService").can(abilityName, resource, aditionalProperties);
    },

    cannot: function cannot(abilityName, resource, aditionalProperties) {
      return !this.can(abilityName, resource, aditionalProperties);
    }
  });
});