define('ember-validators/length', ['exports', 'ember', 'ember-validators/utils/validation-error'], function (exports, _ember, _emberValidatorsUtilsValidationError) {
  'use strict';

  exports['default'] = validateLength;

  /**
   * Copyright 2016, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  var get = _ember['default'].get;
  var assert = _ember['default'].assert;
  var isNone = _ember['default'].isNone;
  var isEmpty = _ember['default'].isEmpty;
  var getProperties = _ember['default'].getProperties;

  /**
   *  @class Length
   *  @module Validators
   */

  /**
   * @method validate
   * @param {Any} value
   * @param {Object} options
   * @param {Boolean} options.allowNone If true, skips validation if the value is null or undefined. __Default: true__
   * @param {Boolean} options.allowBlank If true, skips validation if the value is empty
   * @param {Number} options.is The exact length the value can be
   * @param {Number} options.min The minimum length the value can be
   * @param {Number} options.max The maximum length the value can be
   * @param {Object} model
   * @param {String} attribute
   */
  function validateLength(value, options, model, attribute) {
    var _getProperties = getProperties(options, ['allowNone', 'allowBlank', 'is', 'min', 'max']);

    var allowNone = _getProperties.allowNone;
    var allowBlank = _getProperties.allowBlank;
    var is = _getProperties.is;
    var min = _getProperties.min;
    var max = _getProperties.max;

    assert('[validator:length] [' + attribute + '] no options were passed in', !isEmpty(Object.keys(options)));

    if (isNone(value)) {
      return allowNone ? true : (0, _emberValidatorsUtilsValidationError['default'])('invalid', value, options);
    }

    if (allowBlank && isEmpty(value)) {
      return true;
    }

    if (!isNone(is) && is !== get(value, 'length')) {
      return (0, _emberValidatorsUtilsValidationError['default'])('wrongLength', value, options);
    }

    if (!isNone(min) && min > get(value, 'length')) {
      return (0, _emberValidatorsUtilsValidationError['default'])('tooShort', value, options);
    }

    if (!isNone(max) && max < get(value, 'length')) {
      return (0, _emberValidatorsUtilsValidationError['default'])('tooLong', value, options);
    }

    return true;
  }
});