define('ember-moment/computeds/to-now', ['exports', 'ember', 'moment', 'ember-moment/utils/is-descriptor'], function (exports, _ember, _moment, _emberMomentUtilsIsDescriptor) {
  'use strict';

  exports['default'] = computedAgo;

  var _get = _ember['default'].get;
  var emberComputed = _ember['default'].computed;

  function computedAgo(date, maybeInputFormat, maybeHidePrefix) {
    var args = [date];

    var computed = emberComputed(date, {
      get: function get() {
        var momentArgs = [_get(this, date)];

        if (arguments.length > 1) {
          var desc = _emberMomentUtilsIsDescriptor['default'].call(this, maybeInputFormat);
          var input = desc ? _get(this, maybeInputFormat) : maybeInputFormat;

          if (desc && computed._dependentKeys.indexOf(maybeInputFormat) === -1) {
            computed.property(maybeInputFormat);
          }

          momentArgs.push(input);
        }

        return _moment['default'].apply(this, momentArgs).toNow(maybeHidePrefix);
      }
    });

    return computed.property.apply(computed, args).readOnly();
  }
});