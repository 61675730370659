define('dc-tabs/components/dc-tab-list', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var A = _ember['default'].A;
  var ArrayProxy = _ember['default'].ArrayProxy;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var on = _ember['default'].on;
  var alias = _ember['default'].computed.alias;

  exports['default'] = Component.extend({
    tagName: 'dc-tab-list',

    attributeBindings: ['role', 'aria-multiselectable'],

    tabsComponent: alias('parentView'),
    target: alias('tabsComponent'),

    role: 'tablist',

    'aria-multiselectable': false,

    activeTab: alias('tabsComponent.activeTab'),

    registerTabListOnInit: (function () {
      _ember['default'].run.schedule('afterRender', this, function () {
        this.send('registerTabList', this);
      });
    }).on('init'),

    tabs: computed(function () {
      return ArrayProxy.create({
        content: new A()
      });
    }),

    navigateOnKeyDown: on('keyDown', function (event) {
      var key = event.keyCode;
      if (key === 37 /*<*/ || key === 38 /*^*/) {
          this.selectPrevious();
        } else if (key === 39 /*>*/ || key === 40 /*v*/) {
          this.selectNext();
        } else {
        return;
      }
      event.preventDefault();
    }),

    activeTabIndex: computed('activeTab', function () {
      return this.get('tabs').indexOf(this.get('activeTab'));
    }),

    selectNext: function selectNext() {
      var index = this.get('activeTabIndex') + 1;
      if (index === this.get('tabs.length')) {
        index = 0;
      }
      this.selectTabAtIndex(index);
    },

    selectPrevious: function selectPrevious() {
      var index = this.get('activeTabIndex') - 1;
      if (index === -1) {
        index = this.get('tabs.length') - 1;
      }
      this.selectTabAtIndex(index);
    },

    selectTabAtIndex: function selectTabAtIndex(index) {
      var tab = this.get('tabs').objectAt(index);
      tab.select({ focus: true });
    },

    actions: {
      registerTab: function registerTab(tab) {
        this.get('tabs').addObject(tab);
      },

      unregisterTab: function unregisterTab(tab) {
        this.get('tabs').removeObject(tab);
      }
    }
  });
});