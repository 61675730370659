define("ember-concurrency/-task-group", ["exports", "ember-concurrency/utils", "ember-concurrency/-task-state-mixin", "ember-concurrency/-property-modifiers-mixin"], function (exports, _emberConcurrencyUtils, _emberConcurrencyTaskStateMixin, _emberConcurrencyPropertyModifiersMixin) {
  "use strict";

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } });if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var TaskGroup = Ember.Object.extend(_emberConcurrencyTaskStateMixin["default"], {
    isTaskGroup: true,

    toString: function toString() {
      return '<TaskGroup:' + this._propertyName + '>';
    },

    _numRunningOrNumQueued: Ember.computed.or('numRunning', 'numQueued'),
    isRunning: Ember.computed.bool('_numRunningOrNumQueued'),
    isQueued: false
  });

  exports.TaskGroup = TaskGroup;
  var TaskGroupProperty = void 0;

  exports.TaskGroupProperty = TaskGroupProperty;
  if (false) {
    exports.TaskGroupProperty = TaskGroupProperty = function TaskGroupProperty() {
      _classCallCheck(this, TaskGroupProperty);
    };
  } else {
    exports.TaskGroupProperty = TaskGroupProperty = (function (_ComputedProperty2) {
      _inherits(TaskGroupProperty, _ComputedProperty2);

      function TaskGroupProperty() {
        _classCallCheck(this, TaskGroupProperty);

        return _possibleConstructorReturn(this, (TaskGroupProperty.__proto__ || Object.getPrototypeOf(TaskGroupProperty)).apply(this, arguments));
      }

      return TaskGroupProperty;
    })(_emberConcurrencyUtils._ComputedProperty);
  }

  (0, _emberConcurrencyUtils.objectAssign)(TaskGroupProperty.prototype, _emberConcurrencyPropertyModifiersMixin.propertyModifiers);
});