define("ember-concurrency/helpers/task", ["exports"], function (exports) {
    "use strict";

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }return arr2;
        } else {
            return Array.from(arr);
        }
    }

    function _toArray(arr) {
        return Array.isArray(arr) ? arr : Array.from(arr);
    }

    function taskHelper(_ref) {
        var _ref2 = _toArray(_ref),
            task = _ref2[0],
            args = _ref2.slice(1);

        return task._curry.apply(task, _toConsumableArray(args));
    }

    exports["default"] = Ember.Helper.helper(taskHelper);
});