define('ember-sortable/system/scroll-parent', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var $ = _ember['default'].$;

  exports['default'] = function ($element) {
    var position = $element.css('position');
    var excludeStaticParent = position === 'absolute';
    var $scrollParent = $element.parents().filter(function () {
      var $parent = $(this);
      if (excludeStaticParent && $parent.css('position') === 'static') {
        return false;
      }

      var _$parent$css = $parent.css(['overflow', 'overflowX', 'overflowY']);

      var overflow = _$parent$css.overflow;
      var overflowX = _$parent$css.overflowX;
      var overflowY = _$parent$css.overflowY;

      return (/(auto|scroll)/.test(overflow + overflowX + overflowY)
      );
    }).eq(0);

    if ($scrollParent.length === 0 || $scrollParent[0] === document.body) {
      $scrollParent = $(document);
    }
    return position === 'fixed' || $scrollParent;
  };
});