define('ember-linkify/helpers/linkify', ['exports', 'ember', 'ember-linkify/utils/url-regex'], function (exports, _ember, _emberLinkifyUtilsUrlRegex) {
  'use strict';

  exports.linkify = linkify;

  function linkify(textToLinkify, windowTarget) {
    windowTarget = windowTarget || "_self";
    textToLinkify = _ember['default'].Handlebars.Utils.escapeExpression(textToLinkify);

    textToLinkify = textToLinkify.replace((0, _emberLinkifyUtilsUrlRegex['default'])(), function (s) {
      var url;
      if (s.trim().match(/^www\./ig)) {
        url = '//' + s.trim();
      } else {
        url = s.trim();
      }
      return ' <a href="' + url + '" target="' + windowTarget + '">' + s.trim() + '</a> ';
    });

    return _ember['default'].String.htmlSafe(textToLinkify);
  }

  exports['default'] = _ember['default'].Helper.helper(linkify);
});