define('ember-can/helpers/cannot', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Helper.extend({
    helper: _ember['default'].computed(function () {
      return _ember['default'].getOwner(this).lookup('helper:can');
    }),

    compute: function compute(params, hash) {
      return !this.get('helper').compute(params, hash);
    }
  });
});