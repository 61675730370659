define('ember-i18n/config/constants', ['exports'], function (exports) {
  'use strict';

  var ZERO = 'zero';
  exports.ZERO = ZERO;

  var ONE = 'one';
  exports.ONE = ONE;

  var TWO = 'two';
  exports.TWO = TWO;

  var FEW = 'few';
  exports.FEW = FEW;

  var MANY = 'many';
  exports.MANY = MANY;

  var OTHER = 'other';
  exports.OTHER = OTHER;
});