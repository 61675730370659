define('dc-tabs/components/dc-tab', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var on = _ember['default'].on;
  var alias = _ember['default'].computed.alias;
  var schedule = _ember['default'].run.schedule;

  exports['default'] = Component.extend({

    tagName: 'dc-tab',

    attributeBindings: ['role', 'aria-controls', 'aria-selected', 'aria-expanded', 'tabindex', 'selected'],

    target: alias('tabList'),
    role: 'tab',

    selected: computed('active', function () {
      return this.get('active') ? 'selected' : null;
    }),

    tabindex: computed('active', function () {
      return this.get('active') ? 0 : null;
    }),

    tabs: alias('tabList.tabsComponent'),
    tabList: (function () {
      return this.get('parentView');
    }).property(),
    'aria-controls': alias('tabPanel.elementId'),
    'aria-selected': computed('active', function () {
      return this.get('active').toString();
    }),
    'aria-expanded': alias('aria-selected'),

    selectFromTabsSelectedIndexOnInit: (function () {
      _ember['default'].run.schedule('afterRender', this, function () {
        this.send('registerTab', this);
        this.selectFromTabsSelectedIndex();
      });
    }).on('init'),

    willDestroyElement: function willDestroyElement() {
      this.send('unregisterTab', this);
    },

    active: computed('tabs.activeTab', function () {
      return this.get('tabs.activeTab') === this;
    }),

    select: on('click', function (options) {
      this.get('tabs').select(this);
      if (options && options.focus) {
        schedule('afterRender', this, function () {
          this.$().focus();
        });
      }
    }),

    index: computed('tabList.tabs.[]', function () {
      return this.get('tabList.tabs').indexOf(this);
    }),

    tabPanel: computed('tabs.tabPanels.[]', function () {
      var index = this.get('index');
      var panels = this.get('tabs.tabPanels');
      return panels && panels.objectAt(index);
    }),

    selectFromTabsSelectedIndex: observer('tabs.selected-index', function () {
      var activeTab = this.get('tabs.activeTab');
      if (activeTab === this) {
        return; // this was just selected
      }
      var selectedIndex = this.get('tabs.selected-index');
      var index = parseInt(this.get('tabs.selected-index'), 10);
      var myIndex = this.get('index');
      if (index === myIndex || this.get('custom-index') && this.get('custom-index') === selectedIndex) {
        this.select();
      }
    })
  });
});